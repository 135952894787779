<template>
  <main ref="main" class="nav-gap">
    <!-- //hero section// -->
    <section
      class="hero"
      data-aos="flip-left"
      data-aos-delay="500"
      data-aos-easing="ease-in"
    >
      <div class="top-hero">
        <div class="hero-rgt">
          <div class="top-clm">
            <i class="fa fa-google-wallet" aria-hidden="true"></i>
            <span>Your Digital Dreams To Life</span>
          </div>
          <h1><span class="hero-italics">Hello,</span></h1>
          <p>
            Welcome to My Portfolio, where creativity meets functionality in the
            digital realm. As a passionate web designer and developer, I
            specialize in crafting engaging, user-centric digital experiences
            that leave a lasting impression.
          </p>

          <div class="btm-clm">
            <div>
              <router-link to="/contact" class="myButton">Connect</router-link>
            </div>
            <div class="btm-clm">
              <span>On A Journey Of Creativitye</span>
              <span
                ><i class="fa fa-long-arrow-right" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-btm"></div>
    </section>
    <!-- //second hero// -->
    <!-- <section class="hero-2" data-aos="flip-left" data-aos-delay="250">
      <div class="top-hero-box">
        <div class="box-1">
          <div class="top-clm">
            <router-link to="/contact">
              <i class="fa fa-google-wallet" aria-hidden="true"></i>
              <span>Relentless Pursuit Of Excellence</span>
            </router-link>
          </div>
          <h1>What Make Me Special ?</h1>

          <p>
            Within these digital walls, you'll discover a curated collection of
            my finest creations, each a testament to my dedication to the craft
            and my relentless pursuit of excellence. From sleek and modern
            websites to dynamic web applications, every project in this
            portfolio is a labor of love, meticulously crafted to meet the
            unique needs and objectives of my clients.
          </p>
          <div class="btm-clm-sect-2">
            <div class="btm-clm">
              <router-link to="/contact" class="myButton">Connect</router-link>
            </div>
            <div class="btm-clm-sect-2">
              <span>On A Journey Of Creativitye</span>
              <span
                ><i class="fa fa-long-arrow-right" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- //portfolio// -->
    <section
      class="section-4"
      data-aos="zoom-out"
      data-aos-delay="250"
      data-aos-easing="ease-in"
    >
      <div class="row-strt">
        <h1>My Works</h1>
        <p>
          Explore my portfolio to witness the fusion of design innovation and
          technical expertise, and envision how we can collaborate to elevate
          your digital presence to new heights. Let's embark on a journey of
          creativity, innovation, and success together.
        </p>
      </div>
      <div class="row">
        <div class="div-box" v-for="website of websitelist" :key="website.id">
          <a :href="website.url" target="_blank" rel="noopener noreferrer">
            <div class="upper-bx">
              <img
                :src="image_resolve(website.tech[0])"
                alt="tech"
                class="badge-img"
              />
              <img
                :src="image_resolve(website.img)"
                alt="bx-img"
                class="bx-img"
                loading="lazy"
              />
              <div class="overlay"></div>
            </div>

            <div class="title-btm-bx">
              <h1>{{ website.name }}</h1>
              <p>{{ website.dis }}</p>
              <div class="tech-bx">
                <div v-for="web in website.tech" :key="web">
                  <img :src="image_resolve(web)" alt="tech" class="tech-img" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
    <!-- //framework// -->
    <section
      class="section-5"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-delay="250"
    >
      <div class="row-strt">
        <h1>
          Design Aesthetics And A Mastery Of The Latest Web Technologies !
        </h1>
        <p>
          With a keen eye for design aesthetics and a mastery of the latest web
          technologies, I bring ideas to life in the digital landscape,
          seamlessly blending form and function to deliver intuitive, visually
          stunning, and highly functional web solutions.
        </p>
      </div>
      <div class="row">
        <div class="circle-2" style="transform: translateY(-40%)">
          <img src="../assets/angular.png" alt="bx-img" loading="lazy" />
        </div>
        <div class="circle">
          <img src="../assets/vue.png" alt="bx-img" loading="lazy" />
        </div>

        <div class="circle" style="transform: translateY(40%)">
          <img src="../assets/django.png" alt="bx-img" loading="lazy" />
        </div>
        <div class="circle">
          <img src="../assets/api.png" alt="bx-img" loading="lazy" />
        </div>

        <div class="circle-2" style="transform: translateY(-40%)">
          <img src="../assets/html.png" alt="bx-img" loading="lazy" />
        </div>
        <div class="circle">
          <img src="../assets/css.png" alt="bx-img" loading="lazy" />
        </div>
        <!-- </div>
  <div class="row"> -->
        <div class="circle" style="transform: translateY(40%)">
          <img src="../assets/bootstrap.png" alt="bx-img" loading="lazy" />
        </div>
        <div class="circle">
          <img src="../assets/scss.png" alt="bx-img" loading="lazy" />
        </div>
        <div class="circle-2" style="transform: translateY(40%)">
          <img src="../assets/react.png" alt="bx-img" loading="lazy" />
        </div>
        <div class="circle" style="transform: translateY(40%)">
          <img src="../assets/wordpress.png" alt="bx-img" loading="lazy" />
        </div>
        <div class="circle-2">
          <img src="../assets/js.png" alt="bx-img" loading="lazy" />
        </div>
      </div>
    </section>
  </main>
</template>
<script setup>
// import { onMounted, ref, onUnmounted } from "vue";
import data from "../db/data.json";
import image_resolve from "../mixin/resolve";
import { useHead } from "@vueuse/head";
let websitelist = data.website;
useHead({
  title: "My Portfolio",
  meta: [
    { name: "description", content: "My beautiful website" },
    { property: "og:title", content: "My Portfolio" },
    { property: "og:type", content: "Portfolio" },
    { property: "og:url", content: "https://portfolio.wecoxer.com" },
    {
      property: "og:image",
      content: "https://yogesh.wecoxer.com/assets/yog-563a67fb.jpg",
    },
  ],
});

// const main = ref(null);
// let allsections = [];

// onMounted(() => {
//   allsections = document.querySelectorAll("section");
//   allsections.forEach((section) => {
//     observer.observe(section);
//     //   section.classList.add("section--hidden");
//   });
// });
// onUnmounted(() => {
//   allsections.forEach((section) => {
//     observer.unobserve(section);
//   });
// });
// let onElementObserved = (entries) => {
//   entries.forEach(({ target, isIntersecting }) => {
//     console.log(target, isIntersecting);
//     if (!isIntersecting) target.classList.remove("hidden");
//     if (isIntersecting) target.className += " hidden";
//   });
// };

// let observer = new IntersectionObserver(onElementObserved, {
//   root: null,
//   rootMargin: "10px 10px",
//   threshold: 1.0,
// });
</script>

<style>
.top-clm > span {
  color: var(--pc);
  font-size: 0.8rem;
  font-weight: 100;
  font-style: italic;
}

.btm-clm > span {
  color: var(--pc);
  font-size: 0.8rem;
  padding-right: 10px;
  font-weight: 100;
  font-style: italic;
}
.btm-clm {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
}
.btm-clm-sect-2 {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.btm-clm-sect-2 > span {
  color: var(--pc);
  font-size: 0.8rem;
  padding-right: 10px;
  font-weight: 100;
  font-style: italic;
}
.myButton {
  color: var(--pc);
  font-size: 22px;
  margin: 20px;
  line-height: 22px;
  padding: 8px;
  border-radius: 50px;

  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;

  border: 12px outset var(--pc);
  display: inline-block;
}
.myButton:hover {
  border: 12px inset var(--pc);
}
.myButton:active {
  background: var(--pc);
}
.top-clm > i {
  font-size: 1rem;
  color: var(--pc);

  padding-right: 20px;
}
main {
  /* background: url("../assets/pngwing.com (1).png"); */
  /* background: linear-gradient(90deg, white 0%, var(--pc) 100%); */
  /* backdrop-filter: blur(20px); */
  background-color: white;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero {
  background: url("../assets/bh.webp"),
    radial-gradient(
      circle,
      rgba(131, 58, 180, 1) 0%,
      rgba(253, 29, 29, 1) 50%,
      rgba(252, 176, 69, 1) 100%
    );
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  background-blend-mode: luminosity;
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0vh 0vw;

  /* align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start; */
}
.hero-2 {
  background: url("../assets/bg11.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;

  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0vh 0vw;

  /* align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start; */
}
.top-hero {
  display: flex;
  /* align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start; */
  max-width: 900px;
  min-height: 100vh;
  flex-direction: column;
  /* background-color: white; */
  /* padding: 15%; */
  padding-left: 5%;
  padding-top: 5%;
  padding-right: 10%;
  position: relative;
}
.top-hero-box {
  display: flex;
  /* align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start; */
  max-width: 900px;
  flex-direction: column;
  /* background-color: white; */
  min-height: 100vh;
  /* padding: 15%; */
  padding-right: 5%;
  padding-top: 5%;
  padding-left: 10%;
  position: relative;
}

.hero-rgt {
  padding: 3em;
  border: 1px solid #dfe1e6;
  background-color: white;
  box-shadow: 10px 10px 10px black;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-radius: 0px;
}
.hero-rgt > h1 {
  font-size: 1.5rem;
  margin-bottom: 0;
  padding-bottom: 0;
  color: var(--pc);
  text-transform: uppercase;
}
/* .hero-italics {
  color: white;
} */
.hero-rgt > p {
  font-size: 16px;
  letter-spacing: 2px;

  line-height: 1.6rem;

  padding-top: 0;
}

.box-1 {
  background-repeat: repeat-x;
  padding: 3em;
  border: 1px solid #dfe1e6;
  padding: 3em;

  background-color: white;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  border-radius: 0px;
}

.box-1 > h1 {
  font-size: 1.5rem;

  margin-bottom: 0;
  padding-bottom: 0;

  color: var(--pc);
  text-transform: uppercase;
}
.box-1 > span {
  font-size: 1rem;
  line-height: 2rem;

  color: white;
  background-color: var(--pc);
  padding: 1px 10px;

  border-start-start-radius: 20px;
  border-start-end-radius: 20px;
  border-end-start-radius: 20px;
  border-end-end-radius: 20px;
}
.box-1 > p {
  font-size: 16px;
  letter-spacing: 2px;
  text-align: end;
  line-height: 1.6rem;

  padding-top: 0;
}

.section-4 {
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: none;
}
.div-box {
  max-width: 400px;

  box-shadow: inset 20px 20px 20px rgb(0, 0, 0, 0.05),
    25px 35px 20px rgb(0, 0, 0, 0.05), 25px 30px 30px rgb(0, 0, 0, 0.05),
    inset -20px -20px 25px rgb(255, 255, 255, 0.9);

  overflow: hidden;

  background-color: white;
  border: 1px solid #dfe1e6;
}
.div-box:hover {
  box-shadow: outset 20px 20px 20px rgb(0, 0, 0, 0.05),
    25px 35px 20px rgb(0, 0, 0, 0.05), 25px 30px 30px rgb(0, 0, 0, 0.05),
    outset -20px -20px 25px rgb(255, 255, 255, 0.9);
}
.upper-bx {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upper-bx:hover,
.bx-img:hover {
  width: 450px;
  max-width: 480px;
  height: auto;
  transition: all 350ms linear;
}
.bx-img {
  height: auto;
  max-width: 380px;
  cursor: pointer;
}

/* .bx-img:hover + .overlay {
  height: 300px;
  width: 380px;
  background-color: #5e517c64;
  z-index: 1;
  position: absolute;
  animation: slide 1s linear;
} */
.tech-img {
  height: 30px;
}
.badge-img {
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffffff;
}
.tech-bx {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: end;
}
@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.btn-clm-div {
  position: absolute;
  bottom: 30px;
  margin: 5px;
}
.btn-clm > .sm-btn {
  color: inherit;
  border: 1px solid black;
}
.title-4 {
  position: absolute;
  bottom: 70px;
  margin: 5px;
}
.title-4 > h1 {
  color: white;
  font: 3rem f1;
}
.title-4 > p {
  color: white;
  font-size: 1.3rem;
}
.title-btm-bx {
  min-height: 100px;
  padding: 10px;
  /* background: var(--pc); */
}
.title-btm-bx > h1 {
  font-size: 1.5rem;
  color: var(--pc);
  text-transform: capitalize;
}
.title-btm-bx > p {
  font-size: 1rem;
  text-decoration: none;
}
.section-5 {
  width: 100vw;
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  min-height: 150vh;
  flex-direction: column;
  background: none;
}
.row {
  margin-top: 100px;
  display: flex;
  width: 100%;
  padding: 5%;
  margin: 0;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.circle {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  color: whitesmoke;
  margin: 10px;
  overflow: hidden;
  align-items: center;
  display: flex;
  box-shadow: inset 20px 20px 20px rgb(0, 0, 0, 0.05),
    25px 35px 20px rgb(0, 0, 0, 0.05), 25px 30px 30px rgb(0, 0, 0, 0.05),
    inset -20px -20px 25px rgb(255, 255, 255, 0.9);
}
.circle > img {
  min-height: 170px;
  min-width: 170px;
  object-fit: cover;
}
.circle-2 {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  color: white;

  margin: 10px;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  box-shadow: inset 20px 20px 20px rgb(0, 0, 0, 0.05),
    25px 35px 20px rgb(0, 0, 0, 0.05), 25px 30px 30px rgb(0, 0, 0, 0.05),
    inset -20px -20px 25px rgb(255, 255, 255, 0.9);
}
.circle-2 > img {
  min-height: 250px;
  min-width: 250px;
  object-fit: cover;
}
.circle:hover,
.circle-2:hover {
  height: 300px;
  width: 300px;
  transition: all 350ms linear;
}
.row-strt {
  display: flex;
  width: 100%;
  padding: 5% 10% 0% 10%;
  height: fit-content;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  /* box-shadow: inset 20px 20px 20px rgb(0, 0, 0, 0.05),
    25px 35px 20px rgb(0, 0, 0, 0.05), 25px 30px 30px rgb(0, 0, 0, 0.05),
    inset -20px -20px 25px rgb(255, 255, 255, 0.9); */
}
.row-strt > h1 {
  font: 2.5rem f2;
  font-weight: bolder;
  letter-spacing: 1px;
  word-spacing: 2px;
  overflow-wrap: anywhere;
  text-align: left;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  color: var(--pc);
}

a {
  text-decoration: none;
}
@media (width<1500px) {
  .top-hero {
    flex-wrap: wrap;
  }

  .box-1 {
    padding: 10%;
  }
  .row-strt {
    padding: 10%;
  }
  .box-2 {
    display: none;
  }
  .hero-rgt {
    padding-top: 40vh;
  }
}
@media (width<748px) {
  .hero-rgt {
    padding-top: 0rem;
  }
  .hero-lft > h1 {
    font-size: 4rem;
  }
  .hero-lft > h1 > span {
    font-size: 4rem;
    line-height: 4rem;
  }
  .box-1 {
    position: relative;
    font-size: 1rem;
  }
  .box-1 > h1 {
    font-size: 2rem;
  }

  .box-2 {
    position: relative;
  }
  .box-2 > h1 {
    font-size: 2rem;
  }
  .small-tittle {
    font-size: 2rem;
    padding: 2rem 0;
  }
  .italic-tittle {
    font-size: 2rem;
  }
  .section-3 {
    padding: 2rem;
  }
  .section-3 > h1 {
    font-size: 2rem;
  }
  .section-3 > p {
    font-size: 1rem;
  }
  .row-strt > p {
    padding: 10%;
  }
  .row {
    padding-left: 15%;
  }
}
</style>
