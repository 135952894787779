<template>
  <nav>
    <div
      class="log"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-delay="250"
    >
      <img src="../assets/logo.png" class="logo" alt="logo" srcset="" />
    </div>
    <div class="menu">
      <div
        class="nav-box"
        data-aos="fade-right"
        data-aos-easing="linear"
        data-aos-delay="250"
      >
        <router-link class="nav-link" to="/">
          <i class="fa fa-home link-icon"></i>
          <span class="link-name">Home</span>
        </router-link>
      </div>
      <div
        class="nav-box"
        data-aos="flip-left"
        data-aos-easing="linear"
        data-aos-delay="250"
      >
        <router-link to="/portfolio" class="nav-link">
          <i class="fa fa-magic" aria-hidden="true"></i>
          <span class="link-name">Portfolio</span></router-link
        >
      </div>
      <div
        class="nav-box"
        data-aos="flip-right"
        data-aos-easing="linear"
        data-aos-delay="250"
      >
        <router-link to="/about" class="nav-link">
          <i class="fa fa-user link-icon"></i>
          <span class="link-name">About</span></router-link
        >
      </div>
      <div
        class="nav-box"
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-delay="250"
      >
        <router-link to="/contact" class="nav-link">
          <i class="fa fa-phone link-icon"></i>
          <span class="link-name">Contact</span></router-link
        >
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "NavBarView",
};
</script>

<style scoped>
nav {
  position: fixed;
  background-color: white;
  width: 100vw;
  height: 80px;
  left: 0;
  top: 0;
  border-radius: 0px;
  box-shadow: var(--sw);
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 3%;
  padding-right: 3%;
}
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.nav-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
@media (width<748px) {
  .nav-box {
    padding: 10px;
  }
}
/* nav:hover .link-name {
  display: block;
  padding-left: 5px;
} */
.nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-style: bold;

  color: rgba(83, 83, 83, 0.582);
}
.link-name {
  display: none;
  font-size: 0.8rem;
}
</style>
