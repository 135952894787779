<template>
  <div class="">
    <nav-bar />
    <div :class="[isLoading ? 'preload' : 'hidden']">
      <div class="loading"></div>
    </div>
    <div class="container">
      <router-view></router-view>
    </div>
    <section>
      <div class="contact">
        <div>
          <span class="ph">Contact No:</span
          ><span class="ph-cnt">+971505634896</span>
        </div>
        <div>
          <span class="ph">Email:</span
          ><span class="ph-cnt"> kpradyumnan<strong>@</strong>gmail.com</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { onBeforeMount, ref, onMounted } from "vue";
import NavBar from "@/components/NavBar.vue";
// import { onMounted } from "vue";
import AOS from "aos";
const isLoading = ref(true);
onBeforeMount(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 1000);
});
onMounted(() => {
  AOS.init({
    duration: 2000,
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 0, // offset (in px) from the original trigger point
    delay: 500, // values from 0 to 3000, with step 50ms

    easing: "ease", // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: true, // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
  });
});
</script>

<style>
@import "./fonts/fontawesome.min.css";
/* @import "./fonts/monzerat.woff2"; */
:root {
  --pc: #6e43b6;
  --sc: #a4bebb;
  --tc: #808c40;
  --fc: grey;
  --sw: 4px 0 20px -8px grey;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
@keyframes scaler {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-style: bold;
  color: var(--tc);
}
p {
  font-size: 1rem;
  letter-spacing: 2px;
  color: var(--tc);
  line-height: 1.6rem;
  font-weight: 300;
  padding-top: 0;
  font-style: oblique;
  text-emphasis-color: grey;
  text-transform: capitalize;
  letter-spacing: 1px;
  word-spacing: 2px;
  text-align: left;
  padding-top: 20px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}
span {
  color: var(--pc);
}
html::-webkit-scrollbar {
  scrollbar-width: none;
}
.router-link-active {
  border-bottom: 2px solid black;
}
.container {
  margin-left: 0;
  width: 100%;
  overflow: hidden;
}
.hidden {
  display: none;
}
.preload {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  backdrop-filter: blur(20px);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.loading {
  height: 100px;
  width: 100px;
  background: url("./assets/loading.gif");

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 999999;
}
.nav-gap {
  margin-top: 80px;
}
.logo {
  height: 50px;
}
.contact {
  width: 100vw;
  min-height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #dfe1e6;
  background: #939393;
}
.ph {
  padding-top: 10px;
  font-size: 1rem;
  color: white;
}
.ph-cnt {
  color: white;
}
</style>
